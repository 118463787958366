
import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

const CHeader = () => (
  <StaticQuery
    query={graphql`
      query {
        lager: file(
          sourceInstanceName: { eq: "lager" }
          name: { eq: "laavelager3" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <HeaderWrapper>
        <Container>
          <StyledBgImage fluid={data.lager.childImageSharp.fluid} />
          <Grid>
            <Text>
              <h1>
                Låvelager
              </h1>
              <StyledSlogan>
              Kontakt oss om lagring av Campingvogn
              </StyledSlogan>
            </Text>
          </Grid>
        </Container>
      </HeaderWrapper>
    )}
  />
);

const HeaderWrapper = styled.header`
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
  margin-top: 56px;
`;


const Grid = styled.div`
  display: flex;
  flex-dirextion: column;
  position: relative;
  z-index: 1;
`;

const Text = styled.div`
  justify-self: center;
`;

const StyledSlogan = styled(Text)`
  font-size: 1.2rem;
  color: #fff;
  margin: 1rem 0;
  line-height: 1.4;
  @media screen and  (max-width: 500px) {
    width: 90%;
  }
`

const StyledExternalLink = styled(ExternalLink)`
  color: ${props => props.theme.color.black.regular};
  text-decoration: none;
  background: #fff;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  display: inline-block;
  margin-top: 1rem;
  margin-right: 0.7rem;
  font-size: 1rem;

  &:hover {
    background: ${props => props.theme.color.white.dark};
  }
`;


const StyledBgImage = styled(props => (
  <Img {...props} />
))`
  && {
    height: 40vh;
    object-fit: cover;
    object-position: center 32%;
    position: absolute !important;
    width: 100%;
    left: 0;
    top: 0;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: ${props => props.theme.color.primary};
      opacity: 0.6;
    }
  }
`

export default CHeader;

import React from 'react';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';
import ExternalLink from '@common/ExternalLink';

const FAQS = [
  {
    title: 'Priser lagring av campingvogn',
    content: () => (
      <>
        <p>
          Prisliste for vinterlagring av campingvogn, fra 1. oktober til 30.
          april
        </p>
        <ul>
          <li>Opptil 6 meter: kr 3750,-</li>
          <li>Fra 6 til 7 meter: kr 4200,-</li>
          <li>Fra 7 til 8 meter: kr 4650,-</li>
        </ul>
        <span>* Vognens lengde beregnes inklusiv draget.</span>
      </>
    ),
  },
  {
    title: 'Tilleggstjenester',
    content: () => (
      <>
        <ul>
          <li>Uttak av campingvogn/båt under lagersesong, kr 1500,- pr gang</li>
          <li>
            Fuktsluker ved innlevering for vintersesong og påfyll i januar,
            300,-
          </li>
          <li>Kun påfyll til fuktsluker i januar, kr 120,-</li>
          <li>Oppladning av batteri før utlevering, kr 200,-</li>
        </ul>
      </>
    ),
  },
  {
    title: 'Praktisk informasjon ved lagring av campingvogn',
    content: () => (
      <>
        <p>Ved innlevering skal:</p>
        <ul>
          <li>
            gasstanken tas ut av vognen, og hvis ønskelig oppbevares i et
            adskilt rom i låven
          </li>
          <li>batteri være frakoblet</li>
          <li>vognens vanntank være tømt og kraner åpnet</li>
        </ul>
      </>
    ),
  },
  {
    title: 'Betalingsbetingelser',
    content: () => (
      <>
        <p>
          Ved bestilling/reservasjon av plass skal det betales et depositum på
          kr 1500,-. Reservasjonen er ikke gyldig før innbetaling av depositumet
          er foretatt. Depositumsavtalen blir tilsendt i egen e-post. Ved
          signering/betaling av lagerkontrakt så konverteres depositum om til
          innbetalt forskudd på leie.
        </p>
        <br />
        <p>
          Leiebeløp betales i forkant, eller senest ved levering av objektet som
          skal lagres.
        </p>
      </>
    ),
  },
  {
    title: 'Inn- og utlevering',
    content: () => (
      <>
        <p>
          Campingvognen skal leveres ved vårt lager i oktober, eller etter egen
          avtale mellom utleier og leietaker. Tidspunkt for levering skal
          avtales minst en uke i forveien.
        </p>
        <br />
        <p>
          Utlevering av campingvogn skjer uten ekstra kostnad fra og med
          lørdagen en uke før palmesøndag. Utlevering i sesong koster kr 1500,-.
        </p>
      </>
    ),
  },
  {
    title: 'Forsikring',
    content: () => (
      <>
        <p>
          Eier av campingvogn står selv ansvarlig for forsikring for sitt
          objekt. Bygget er forsikret av Låvelager.
        </p>
      </>
    ),
  },
];

const Faq = () => (
  <Section id="faq">
    <Container>
      <h1 style={{ marginBottom: 40 }}>Vanlige spørsmål</h1>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);



export default Faq;
